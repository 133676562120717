import { AuthorDTO } from '@/types/AuthorDTO';
import { Button, Collapse, Grid, Theme } from '@mui/material';
import { CreateFormAnswerProps } from '@/types/create/CreateFormAnswerProps';
import {
  CreateFormState,
  clearLetterTextAndFile,
  getAttachFile,
  getCrosswordAttached,
  getIdempotencyKey,
  getLetterNumber,
  getLetterSent,
  getLetterUuid,
  getPaymentMethod,
  getPaymentProvider,
  getSudokuAttached,
  getSudokuCount,
} from '@/services/createFormSlice';
import { CreateLetterDTO } from '@/types/create/CreateLetterDTO';
import { DepartmentDTO } from '@/types/DepartmentDTO';
import { DownIcon } from '@/app/icons/DownIcon';
import { Footer } from '@/components/main/footer/Footer';
import { LetterBalance } from '@/components/create/unauthorized/LetterBalance';
import { LetterInfo } from '@/components/create/LetterInfo';
import { LetterReceipt } from '@/components/common/LetterReceipt';
import { LetterStatus } from '@/types/letter/LetterStatus';
import { PageHeader } from '@/components/common/PageHeader';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { RegionDTO } from '@/types/RegionDTO';
import { RootState } from '@/app/store';
import { SenderDTO } from '@/types/SenderDTO';
import { UpIcon } from '@/app/icons/UpIcon';
import { UserDTO } from '@/types/user/UserDTO';
import { calculatePageCount, calculateTotalCost } from '@/utils/price-utils';
import { formatNumberOfLetters } from '@/utils/string-utils';
import { getCurrentUser } from '@/services/authSlice';
import { getLetterCost, getPhotoCost } from '@/utils/currency-utils';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useGetClientInfoQuery } from '@/services/api/createFormApiSlice';
import { useGetLetterByUuidQuery } from '@/services/api/letterApiSlice';
import { useHistory } from 'react-router-dom';
import AttachFilePanel from '@/components/create/AttachFilePanel';
import LetterCostPanel from '@/components/create/LetterCostPanel';
import React, { FC, useEffect, useState } from 'react';

interface Props {
  isAnswerReply?: boolean;
}

export const MainCreateFormPaymentMobile: FC<CreateFormAnswerProps & Props> = (
  props: CreateFormAnswerProps & Props
) => {
  const {
    previousFormPath,
    previousFormLabel,
    errorRedirectPath,
    nextFormPath,
    isMobile,
    mobileTittle,
    fromCreateAnswer,
    isAnswerReply,
  } = props;

  const history = useHistory();
  const dispatch = useAppDispatch();

  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const createFormReducer: CreateFormState = useAppSelector((state: RootState) => {
    return state.createForm;
  });
  const department: DepartmentDTO | null = createFormReducer.department;
  const region: RegionDTO | null = createFormReducer.region;
  const letterText: string | undefined = createFormReducer.letterText;
  const isAnswerOrdered: boolean = createFormReducer.isAnswerOrdered;
  const sender: SenderDTO | null = createFormReducer.sender;
  const author: AuthorDTO | null = createFormReducer.author;
  const answersCount: number = createFormReducer.answersCount;
  const attachFile: File | undefined = useAppSelector(getAttachFile);
  const crosswordAttached: boolean = useAppSelector(getCrosswordAttached);
  const sudokuAttached: boolean = useAppSelector(getSudokuAttached);
  const sudokuCount: number = useAppSelector(getSudokuCount);
  const isLetterSent: boolean = useAppSelector(getLetterSent);
  const idempotencyKey: string | undefined = useAppSelector(getIdempotencyKey);
  const letterNumber: number | undefined = useAppSelector(getLetterNumber);
  const letterUuid: string | undefined = useAppSelector(getLetterUuid);
  const paymentMethod: PaymentMethod = useAppSelector(getPaymentMethod);
  const paymentProvider: PaymentProvider | undefined = useAppSelector(getPaymentProvider);

  if (!(department && region && sender && author)) {
    if (errorRedirectPath) {
      history.push(errorRedirectPath);
    }
  }

  const photoCost: number = getPhotoCost(!!authUser, department);
  const letterCost: number = getLetterCost(!!authUser, department);
  const authorizedLetterCost: number = getLetterCost(true, department);
  const authorizedPhotoCost: number = getPhotoCost(true, department);
  const countCharacters: number | undefined = letterText?.length;

  const { data: letter, refetch: getLetterByUuid } = useGetLetterByUuidQuery(letterUuid, {
    skip: !letterUuid,
    refetchOnMountOrArgChange: true,
  });

  const { data: clientInfo } = useGetClientInfoQuery();
  const charactersPerPage: number | undefined = clientInfo?.charactersPerPage;

  const dto: CreateLetterDTO = {
    department: department?.id,
    author: author,
    sender: sender,
    letter: letterText,
    isAnswerOrdered: isAnswerOrdered,
    answersCount: answersCount,
    crosswordAttached: crosswordAttached,
    sudokuAttached: sudokuAttached,
    sudokuCount: sudokuCount,
  };

  const pageCount: number | undefined = calculatePageCount(countCharacters || 0, charactersPerPage || 0);

  const totalCost: number = calculateTotalCost(
    pageCount,
    isAnswerOrdered,
    answersCount,
    !!attachFile,
    crosswordAttached,
    sudokuAttached,
    sudokuCount,
    letterCost,
    letterCost,
    photoCost
  );

  const authorizedTotalCost: number = calculateTotalCost(
    pageCount,
    isAnswerOrdered,
    answersCount,
    !!attachFile,
    crosswordAttached,
    sudokuAttached,
    sudokuCount,
    authorizedLetterCost,
    authorizedLetterCost,
    authorizedPhotoCost
  );

  const [collapse, setCollapse] = useState<boolean>(false);

  const toChangeAnswerReply = (): void => {
    if (previousFormPath) {
      history.push(previousFormPath);
    }
  };

  useEffect((): void => {
    if (fromCreateAnswer) {
      dispatch(clearLetterTextAndFile());
    }
  }, [dispatch, fromCreateAnswer]);

  useEffect((): void => {
    const timerId: NodeJS.Timer = setInterval((): void => {
      if (!(!!letter && letter?.status !== LetterStatus.UNPAID)) {
        getLetterByUuid();
        clearInterval(timerId);
      }
    }, 30000);
  }, [getLetterByUuid, letter]);

  const isPaymentSuccess: boolean = !!letter && letter?.status !== LetterStatus.UNPAID;

  return (
    <Grid container={true} direction={'column'} spacing={3}>
      <Grid item={true}>
        <Grid container={true} direction={'column'} spacing={2}>
          <Grid item={true}>
            {!isLetterSent ? (
              <PageHeader
                tittle={mobileTittle}
                previousFormPath={previousFormPath}
                previousFormLabel={previousFormLabel}
                isMobile={isMobile}
                mobileTittle={mobileTittle}
              />
            ) : fromCreateAnswer ? (
              <PageHeader
                tittle={mobileTittle}
                isMobile={isMobile}
                mobileTittle={
                  isPaymentSuccess
                    ? `Бланки ответа ${letterNumber && '№ '}${
                        formatNumberOfLetters(letterNumber)?.toString() + ' ' || ''
                      }оплачены`
                    : `Ожидается оплата бланков ответа ${letterNumber && '№ '}${
                        formatNumberOfLetters(letterNumber)?.toString() || ''
                      }`
                }
              />
            ) : (
              <PageHeader
                tittle={mobileTittle}
                isMobile={isMobile}
                mobileTittle={
                  isPaymentSuccess
                    ? `Письмо ${letterNumber && '№ '}${
                        formatNumberOfLetters(letterNumber)?.toString() + ' ' || ''
                      }принято к доставке`
                    : `Ожидается оплата письма ${letterNumber && '№ '}${
                        formatNumberOfLetters(letterNumber)?.toString() || ''
                      }`
                }
              />
            )}
          </Grid>
          {fromCreateAnswer ? (
            <Grid item={true}>
              <Grid container={true} direction={'column'} spacing={2}>
                <Grid item={true}>
                  <LetterInfo
                    department={department}
                    region={region}
                    author={author}
                    sender={sender}
                    letterText={letterText}
                    isMobile={isMobile}
                    fromCreateAnswer={fromCreateAnswer}
                    isAnswerReply={isAnswerReply}
                    changeAnswerReply={toChangeAnswerReply}
                    answersCount={answersCount}
                  />
                </Grid>
                {isLetterSent && (
                  <Grid item={true}>
                    <LetterCostPanel allPrice={totalCost} fromCreateAnswer={fromCreateAnswer} />
                  </Grid>
                )}
              </Grid>
            </Grid>
          ) : (
            <Grid item={true}>
              <Grid container={true} direction={'column'} spacing={3}>
                <Grid item={true} xs={true}>
                  <Button
                    onClick={() => setCollapse(!collapse)}
                    fullWidth={true}
                    variant={'contained'}
                    color={'inherit'}
                    sx={{
                      background: (theme: Theme) => theme.colors.grayBackground,
                      color: (theme: Theme) => theme.colors.textColor,
                    }}>
                    <Grid container={true} justifyContent={'space-between'}>
                      <Grid item={true}>{'Информация о письме'}</Grid>
                      <Grid item={true}>
                        {collapse ? (
                          <UpIcon
                            sx={{
                              display: 'block',
                              color: (theme: Theme) => theme.colors.primary,
                            }}
                          />
                        ) : (
                          <DownIcon
                            sx={{
                              display: 'block',
                              color: (theme: Theme) => theme.colors.primary,
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Button>
                </Grid>
                <Grid item={true}>
                  <Collapse in={collapse}>
                    <Grid container={true} direction={'column'} rowSpacing={2}>
                      <Grid item={true}>
                        <LetterInfo
                          department={department}
                          region={region}
                          author={author}
                          sender={sender}
                          letterText={letterText}
                          isMobile={isMobile}
                          fromCreateAnswer={fromCreateAnswer}
                          isAnswerReply={isAnswerReply}
                          changeAnswerReply={toChangeAnswerReply}
                          answersCount={answersCount}
                        />
                      </Grid>
                      {photoCost && attachFile && (
                        <Grid item={true}>
                          <AttachFilePanel attachedFile={attachFile} photoCost={photoCost} fullWidth={true} />
                        </Grid>
                      )}
                      {isLetterSent && (
                        <Grid item={true}>
                          <LetterCostPanel
                            allPrice={totalCost}
                            countCharacters={countCharacters}
                            pages={pageCount}
                            file={!!photoCost && !!attachFile}
                            answer={isAnswerOrdered}
                            fromCreateAnswer={fromCreateAnswer}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </Collapse>
                </Grid>
                <Grid item={true}>
                  <LetterReceipt
                    countCharacters={countCharacters}
                    isFileAttached={Boolean(attachFile)}
                    crosswordAttached={crosswordAttached}
                    sudokuAttached={sudokuAttached}
                    sudokuCount={sudokuCount}
                    pages={pageCount}
                    letterCost={letterCost}
                    authorizedLetterCost={authorizedLetterCost}
                    photoCost={photoCost}
                    authorizedPhotoCost={authorizedPhotoCost}
                    totalCost={totalCost}
                    authorizedTotalCost={authorizedTotalCost}
                    isAnswerOrdered={isAnswerOrdered || false}
                    answersCount={answersCount}
                    paymentMethod={paymentMethod}
                    paymentProvider={paymentProvider}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid item={true}>
        <LetterBalance
          attachFile={attachFile}
          crosswordAttached={crosswordAttached}
          sudokuAttached={sudokuAttached}
          sudokuCount={sudokuCount}
          isAnswerOrdered={isAnswerOrdered}
          letterCost={letterCost}
          authorizedLetterCost={authorizedLetterCost}
          photoCost={photoCost}
          authorizedPhotoCost={authorizedPhotoCost}
          totalCost={totalCost}
          authorizedTotalCost={authorizedTotalCost}
          afterSuccessPath={nextFormPath}
          letter={dto}
          isLetterSent={isLetterSent}
          isMobile={true}
          pages={pageCount}
          countCharacters={countCharacters}
          fromCreateAnswer={fromCreateAnswer}
          answersCount={answersCount}
          idempotencyKey={idempotencyKey}
          isPaymentSuccess={isPaymentSuccess}
        />
      </Grid>
      {isLetterSent && (
        <Grid item={true}>
          <Footer isMobile={true} />
        </Grid>
      )}
    </Grid>
  );
};
