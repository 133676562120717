import { EnclosureInfoDTO } from '@/types/enclosure/EnclosureInfoDTO';
import { EnclosureViewDTO } from '@/types/enclosure/EnclosureViewDTO';
import { HttpMethod } from '@/types/HttpMethod';
import { apiSlice } from '@/services/api/apiSlice';

export const enclosureApiSlice = apiSlice.injectEndpoints({
  endpoints: (build) => ({
    getEnclosureInfo: build.query<EnclosureInfoDTO, null>({
      query: () => ({
        url: '/enclosures/info',
        method: HttpMethod.GET,
      }),
    }),
    getEnclosureView: build.mutation<EnclosureViewDTO, string>({
      query: (uuid: string) => ({
        url: `enclosures/view`,
        method: HttpMethod.GET,
        params: { uuid: uuid },
      }),
    }),
  }),
});

export const { useGetEnclosureInfoQuery, useGetEnclosureViewMutation } = enclosureApiSlice;
