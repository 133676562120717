import { AnswerPaymentInfo } from '@/components/create/unauthorized/AnswerPaymentInfo';
import { Box, Divider, Grid } from '@mui/material';
import { CreateLetterDTO } from '@/types/create/CreateLetterDTO';
import { CreatedSbpQrDTO } from '@/types/sbp/CreatedSbpQrDTO';
import { LetterPaymentInfo } from '@/components/create/unauthorized/LetterPaymentInfo';
import { LetterPaymentRadioGroup } from '@/components/create/unauthorized/LetterPaymentRadioGroup';
import { LetterReceipt } from '@/components/common/LetterReceipt';
import { PaymentButtonContainer } from '@/components/create/unauthorized/PaymentButtonContainer';
import { PaymentMethod } from '@/types/payment/PaymentMethod';
import { PaymentProvider } from '@/types/payment/PaymentProvider';
import { SbpMethod } from '@/types/sbp/SbpMethod';
import { SpbPaymentContainer } from '@/components/common/sbp/SpbPaymentContainer';
import { Theme } from '@mui/material/styles';
import { UserDTO } from '@/types/user/UserDTO';
import { getCurrentUser } from '@/services/authSlice';
import {
  getPaymentMethod,
  getPaymentProvider,
  setPaymentMethodForm,
  setPaymentProviderForm,
} from '@/services/createFormSlice';
import { updatePaymentMethodAndProvider } from '@/utils/payment-utils';
import { useAppSelector } from '@/app/hooks';
import { useCreateLetterMutation } from '@/services/api/letterApiSlice';
import { useDispatch } from 'react-redux';
import { useGetSystemSettingsQuery } from '@/services/api/settingsApiSlice';
import { useGetUserBalanceQuery } from '@/services/api/balanceApiSlice';
import React, { ChangeEvent, FC, useCallback, useEffect, useState } from 'react';

interface Props {
  attachFile?: File;
  crosswordAttached: boolean;
  sudokuAttached: boolean;
  sudokuCount?: number;
  isAnswerOrdered?: boolean;
  letterCost?: number;
  authorizedLetterCost?: number;
  photoCost?: number;
  authorizedPhotoCost?: number;
  totalCost?: number;
  authorizedTotalCost?: number;
  afterSuccessPath?: string;
  letter: CreateLetterDTO;
  isMobile?: boolean;
  isLetterSent: boolean;
  fromCreateAnswer?: boolean;
  pages?: number;
  countCharacters?: number;
  answersCount?: number;
  isProfileCreated: boolean;
  handleRegistrationClick: () => void;
  cardInfoMessage: string;
  setCardInfoMessage: (value: string) => void;
  sbpQR: CreatedSbpQrDTO | undefined;
  sbpMethod: SbpMethod;
  isInitiative?: boolean;
  isInitiativeSubmitAllowed?: boolean;
  idempotencyKey?: string;
  onSbpQrPaymentSuccess?: () => void;
  isPaymentSuccess?: boolean;
}

export const BalanceInfo: FC<Props> = (props: Props) => {
  const {
    attachFile,
    crosswordAttached,
    sudokuAttached,
    sudokuCount,
    isAnswerOrdered,
    letterCost,
    authorizedLetterCost,
    photoCost,
    authorizedPhotoCost,
    totalCost,
    authorizedTotalCost,
    letter,
    isLetterSent,
    isMobile,
    fromCreateAnswer,
    pages,
    countCharacters,
    answersCount,
    isProfileCreated,
    handleRegistrationClick,
    cardInfoMessage,
    setCardInfoMessage,
    sbpQR,
    sbpMethod,
    isInitiative,
    isInitiativeSubmitAllowed,
    idempotencyKey,
    onSbpQrPaymentSuccess,
    isPaymentSuccess,
  } = props;

  const dispatch = useDispatch();

  const authUser: UserDTO | undefined = useAppSelector(getCurrentUser);
  const paymentMethod: PaymentMethod = useAppSelector(getPaymentMethod);
  const paymentProvider: PaymentProvider | undefined = useAppSelector(getPaymentProvider);

  const { data: balance } = useGetUserBalanceQuery(authUser?.id, {
    skip: !authUser,
  });

  const { data: systemSettings, refetch: getSystemSettings } = useGetSystemSettingsQuery();
  const [, { isLoading: isLetterLoading }] = useCreateLetterMutation();

  const [cardNumber, setCardNumber] = useState<string>('');
  const [cardCode, setCardCode] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const handleRadioChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const paymentValue: { method: PaymentMethod; provider: PaymentProvider } = JSON.parse(e.target.value);
    handlePaymentValueChange(paymentValue.method, paymentValue.provider);
  };

  const handlePaymentValueChange = useCallback(
    (method: PaymentMethod, provider?: PaymentProvider): void => {
      dispatch(setPaymentMethodForm(method));
      dispatch(setPaymentProviderForm(provider));
    },
    [dispatch]
  );

  const stringifyPaymentValue = (method: PaymentMethod, provider?: PaymentProvider): string => {
    if (method === PaymentMethod.ONLINE || method === PaymentMethod.SBP) {
      return JSON.stringify({ method: method, provider: provider });
    } else {
      return JSON.stringify({ method: method });
    }
  };

  useEffect((): void => {
    getSystemSettings();
    updatePaymentMethodAndProvider(
      (paymentMethod: PaymentMethod | undefined, paymentProvider: PaymentProvider | undefined) =>
        handlePaymentValueChange(paymentMethod || PaymentMethod.SBP, paymentProvider || PaymentProvider.ALFA),
      paymentMethod,
      paymentProvider,
      systemSettings
    );
  }, [getSystemSettings, handlePaymentValueChange, paymentMethod, paymentProvider, systemSettings]);

  return (
    <Box
      sx={{
        background: (theme: Theme) => theme.colors.grayBackground,
        borderRadius: '12px',
        padding: '32px 24px',
        marginLeft: isMobile ? '-16px' : 'inherit',
        marginRight: isMobile ? '-16px' : 'inherit',
      }}>
      <Grid container={true} direction={isLetterSent ? 'column-reverse' : 'column'} spacing={2}>
        <Grid item={true}>
          {!isLetterSent ? (
            <LetterPaymentRadioGroup
              value={stringifyPaymentValue(paymentMethod, paymentProvider)}
              settings={systemSettings}
              cardNumber={cardNumber}
              onCardNumberChange={setCardNumber}
              cardCode={cardCode}
              onCardCodeChange={setCardCode}
              cardErrorMessage={errorMessage}
              balanceAmount={balance?.amount}
              totalCost={totalCost}
              authorizedTotalCost={authorizedTotalCost}
              isAuthenticated={!!authUser}
              isLetterLoading={isLetterLoading}
              isInitiative={isInitiative}
              onChange={handleRadioChange}
            />
          ) : sbpQR ? (
            <Grid item={true}>
              <SpbPaymentContainer
                sbpQR={sbpQR}
                isMobile={isMobile}
                sbpMethod={sbpMethod}
                isInitiative={isInitiative}
                email={isInitiative ? letter.email || '' : letter.author?.email || ''}
                onSuccess={onSbpQrPaymentSuccess}
              />
            </Grid>
          ) : fromCreateAnswer ? (
            <AnswerPaymentInfo letter={letter} message={cardInfoMessage} isPaymentSuccess={isPaymentSuccess} />
          ) : (
            <LetterPaymentInfo
              letter={letter}
              isInitiative={isInitiative}
              cardInfoMessage={cardInfoMessage}
              isProfileCreated={isProfileCreated}
              onRegistrationClick={handleRegistrationClick}
              isPaymentSuccess={isPaymentSuccess}
            />
          )}
        </Grid>
        <Grid item={true} ml={-3} mr={-3}>
          <Divider />
        </Grid>
        <Grid item={true}>
          <LetterReceipt
            countCharacters={countCharacters}
            isFileAttached={Boolean(attachFile)}
            crosswordAttached={crosswordAttached}
            sudokuAttached={sudokuAttached}
            sudokuCount={sudokuCount}
            pages={pages}
            letterCost={letterCost}
            authorizedLetterCost={authorizedLetterCost}
            photoCost={photoCost}
            authorizedPhotoCost={authorizedPhotoCost}
            totalCost={totalCost}
            authorizedTotalCost={authorizedTotalCost}
            isAnswerOrdered={isAnswerOrdered || false}
            answersCount={answersCount}
            paymentMethod={paymentMethod}
            paymentProvider={paymentProvider}
            isInitiative={isInitiative}
          />
        </Grid>
        {!isLetterSent && (
          <Grid item={true}>
            <PaymentButtonContainer
              paymentMethod={paymentMethod}
              paymentProvider={paymentProvider}
              isMobile={isMobile}
              letter={letter}
              attachFile={attachFile}
              price={totalCost}
              setCardInfoMessage={setCardInfoMessage}
              setErrorMessage={setErrorMessage}
              cardNumber={cardNumber}
              cardCode={cardCode}
              sbpMethod={sbpMethod}
              isInitiative={isInitiative}
              isInitiativeSubmitAllowed={isInitiativeSubmitAllowed}
              idempotencyKey={idempotencyKey}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};
