import { CreateFormClient } from '@/components/create/letter/CreateFormClient';
import { CreateFormLetter } from '@/components/create/letter/CreateFormLetter';
import { LetterSuccess } from '@/components/create/letter/LetterSuccess';
import { MainCreateFormPayment } from '@/components/create/main/letter/MainCreateFormPayment';
import { MainCreateFormPaymentMobile } from '@/components/create/main/letter/MainCreateFormPaymentMobile';
import {
  ROUTE_CREATE_LETTER_AUTHOR,
  ROUTE_CREATE_LETTER_CLIENT,
  ROUTE_CREATE_LETTER_DEPARTMENT,
  ROUTE_CREATE_LETTER_PAYMENT,
  ROUTE_CREATE_LETTER_SUCCESS,
  ROUTE_CREATE_LETTER_TEXT,
  ROUTE_INBOX,
  ROUTE_MAIN_CREATE_LETTER_DEPARTMENT,
  ROUTE_MAIN_CREATE_LETTER_SUCCESS,
  ROUTE_MAIN_CREATE_LETTER_TEXT,
} from '@/app/routes';
import { Redirect, Route, Switch } from 'react-router-dom';
import { RootState } from '@/app/store';
import { Theme } from '@mui/material/styles';
import { clearFormState, clearLetterDraftId } from '@/services/createFormSlice';
import { clearLetterSbpState } from '@/services/sbpSlice';
import { useAppDispatch, useAppSelector } from '@/app/hooks';
import { useMediaQuery } from '@mui/material';
import CreateFormAuthorMobile from '@/components/create/letter/CreateFormAuthorMobile';
import CreateFormDepartment from '@/components/create/letter/CreateFormDepartment';
import CreateFormSender from '@/components/create/letter/CreateFormSender';
import React, { FC, useEffect } from 'react';

const LetterBlock: FC = () => {
  const dispatch = useAppDispatch();
  const isMobile: boolean = useMediaQuery((theme: Theme) => {
    return theme.breakpoints.down('sm');
  });
  const createFormReducer = useAppSelector((state: RootState) => {
    return state.createForm;
  });
  const isLetterSent = createFormReducer.isLetterSent;
  useEffect(() => {
    return () => {
      if (isLetterSent) {
        dispatch(clearFormState());
        dispatch(clearLetterSbpState());
      }
    };
  }, [isLetterSent]);

  useEffect(() => {
    return () => {
      dispatch(clearLetterDraftId());
    };
  }, []);

  return (
    <Switch>
      <Route exact={true} path={ROUTE_CREATE_LETTER_DEPARTMENT}>
        <CreateFormDepartment
          tittle={'Шаг 1 из 4: Выберите учреждение'}
          mobileTittle={'1/5: Выберите учреждение'}
          previousFormPath={ROUTE_INBOX}
          previousFormLabel={'Входящие'}
          nextFormPath={ROUTE_CREATE_LETTER_CLIENT}
          isMobile={isMobile}
        />
      </Route>
      <Route exact={true} path={ROUTE_CREATE_LETTER_CLIENT}>
        {isMobile ? (
          <CreateFormSender
            mobileTittle={'2/5: Введите данные получателя'}
            nextFormPath={ROUTE_CREATE_LETTER_AUTHOR}
            previousFormPath={ROUTE_CREATE_LETTER_DEPARTMENT}
            previousFormLabel={'Шаг 1'}
            errorRedirectPath={ROUTE_CREATE_LETTER_DEPARTMENT}
            isMobile={isMobile}
          />
        ) : (
          <CreateFormClient
            tittle={'Шаг 2 из 4: Введите данные получателя и отправителя'}
            nextFormPath={ROUTE_CREATE_LETTER_TEXT}
            previousFormPath={ROUTE_CREATE_LETTER_DEPARTMENT}
            previousFormLabel={'Шаг 1'}
            errorRedirectPath={ROUTE_CREATE_LETTER_DEPARTMENT}
            isMobile={isMobile}
          />
        )}
      </Route>
      <Route exact={true} path={ROUTE_CREATE_LETTER_AUTHOR}>
        {isMobile ? (
          <CreateFormAuthorMobile
            mobileTittle={'3/5: Введите данные отправителя'}
            nextFormPath={ROUTE_CREATE_LETTER_TEXT}
            previousFormPath={ROUTE_CREATE_LETTER_CLIENT}
            previousFormLabel={'Шаг 2'}
            errorRedirectPath={ROUTE_CREATE_LETTER_CLIENT}
            isMobile={isMobile}
          />
        ) : (
          <CreateFormClient
            tittle={'Шаг 2 из 4: Введите данные получателя и отправителя'}
            nextFormPath={ROUTE_CREATE_LETTER_TEXT}
            previousFormPath={ROUTE_CREATE_LETTER_DEPARTMENT}
            previousFormLabel={'Шаг 1'}
            errorRedirectPath={ROUTE_CREATE_LETTER_DEPARTMENT}
            isMobile={isMobile}
          />
        )}
      </Route>
      <Route exact={true} path={ROUTE_CREATE_LETTER_TEXT}>
        <CreateFormLetter
          tittle={'Шаг 3 из 4: Напишите письмо'}
          mobileTittle={'4/5: Напишите письмо'}
          nextFormPath={ROUTE_CREATE_LETTER_PAYMENT}
          previousFormPath={ROUTE_CREATE_LETTER_AUTHOR}
          previousFormLabel={isMobile ? 'Шаг 3' : 'Шаг 2'}
          errorRedirectPath={ROUTE_CREATE_LETTER_DEPARTMENT}
          isMobile={isMobile}
        />
      </Route>
      <Route exact={true} path={ROUTE_CREATE_LETTER_PAYMENT}>
        {isMobile ? (
          <MainCreateFormPaymentMobile
            mobileTittle={'5/5: Оплатите письмо'}
            nextFormPath={ROUTE_MAIN_CREATE_LETTER_SUCCESS}
            previousFormPath={ROUTE_MAIN_CREATE_LETTER_TEXT}
            previousFormLabel={'Шаг 4'}
            errorRedirectPath={ROUTE_MAIN_CREATE_LETTER_DEPARTMENT}
            isMobile={isMobile}
          />
        ) : (
          <MainCreateFormPayment
            tittle={'Шаг 4 из 4: Оплатите письмо'}
            nextFormPath={ROUTE_MAIN_CREATE_LETTER_SUCCESS}
            previousFormPath={ROUTE_MAIN_CREATE_LETTER_TEXT}
            previousFormLabel={'Шаг 3'}
            errorRedirectPath={ROUTE_MAIN_CREATE_LETTER_DEPARTMENT}
          />
        )}
      </Route>
      <Route exact={true} path={ROUTE_CREATE_LETTER_SUCCESS}>
        <LetterSuccess isMobile={isMobile} />
      </Route>
      <Redirect to={ROUTE_CREATE_LETTER_DEPARTMENT} />
    </Switch>
  );
};

export default LetterBlock;
